<template>
  <el-card class="box-card">
    <div class="lockding">
      <img :src="require('../../../assets/imgs/suoding.png')" />
      <p>账号锁定</p>
      <p>
        您已连续6次登录验证失败，该账号目前已被锁定。
        您可通过手机号验证解锁，立即前往解锁？
      </p>
      <div class="backbtn">
        <button>返回</button>
        <button @click="toUnlocking">解锁</button>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  methods: {
    toUnlocking() {
      this.$router.push("userunlock");
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  /* 卡片 */
  width: 616px;
  padding: 68px 53px 76px 54px;
  box-sizing: border-box;
  margin-right: 12%;
  position: relative;
}
.lockding {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 131px;
    height: 131px;
  }
  p {
    &:first-of-type {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      margin-top: 21px;
    }
    &:last-of-type {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 36px;
      text-align: center;
      margin-top: 52px;
    }
  }
  .backbtn {
    margin-top: 77px;
    button {
      border: none;
      outline-style: none;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      &:first-of-type {
        width: 153px;
        height: 65px;
        background: #ffffff;
        border: 2px solid #272827;
        border-radius: 6px;
        color: #424242;
        margin-right: 51px;
      }
      &:last-of-type {
        width: 153px;
        height: 65px;
        background: #272827;
        border-radius: 6px;
        color: #ffffff;
      }
    }
  }
}
</style>
